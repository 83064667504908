<template>

<div>

  <div v-if="index % 2 === 0" class="d-flex border-bottom p-3">

      <div class="form-check pl-0">
        <div class="form-group mb-0">
          <div class="custom-control custom-checkbox">
            <input
                type="checkbox"
                class="custom-control-input check"
                :id="'mail'+index"
                v-model="resume.isSelected"
            />
            <label class="custom-control-label" :for="'mail'+index"></label>
          </div>
        </div>
      </div>
      <!--<a :href="$router.push({path:'/resume/'+resume.resume_id})">-->
        <a>
        <div class="media ml-2">
          <img
              :src="resume.img_url"
              class="avatar avatar-md-sm rounded-pill shadow"
              @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
              alt=""
              @click="selectItem"
          />
          <div class="content ml-3">
            <h6 class="text-dark">
              <span @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                    @click="selectItem">{{resume.full_name}}</span>
              <a @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                 @click="goToResume" class="text-primary ml-3"
              >Резюме <i class="uil uil-arrow-right"></i
              ></a>

            </h6>

            <p class="text-muted mb-0">
              {{resume.headline}} <span class="badge badge-pill" :class="resume.emailTemplate_badge"> {{resume.emailTemplate}} </span>
              <i v-if="resume.isSent" class="uil text-primary uil-check"></i>
            </p>
          </div>
        </div>
      </a>


  </div>

  <div v-else class="d-flex border-bottom bg-light p-3">


    <div class="form-check pl-0">
      <div class="form-group mb-0">
        <div class="custom-control custom-checkbox">
          <input
              type="checkbox"
              class="custom-control-input check"
              :id="'mail'+index"
              v-model="resume.isSelected"
          />
          <label class="custom-control-label" :for="'mail'+index"></label>
        </div>
      </div>
    </div>
    <!--<a :href="$router.push({path:'/resume/'+resume.resume_id})">-->
    <a>
    <div class="media ml-2">
        <img
            :src="resume.img_url"
            class="avatar avatar-md-sm rounded-pill shadow"
            @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
            alt=""
            @click="selectItem"
        />
        <div class="content ml-3">
          <h6 class="text-dark">
              <span @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
              @click="selectItem">{{resume.full_name}}</span>
            <a @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
               @click="goToResume" class="text-primary ml-3"
            >Резюме <i class="uil uil-arrow-right"></i
            ></a>

          </h6>
          <p class="text-muted mb-0">
            {{resume.headline}} <span class="badge badge-pill" :class="resume.emailTemplate_badge"> {{resume.emailTemplate}} </span>
            <i v-if="resume.isSent" class="uil text-primary uil-check"></i>
          </p>
        </div>
      </div>
    </a>

  </div>


</div>


</template>

<script>

import { EditIcon,TrashIcon,CheckIcon } from "vue-feather-icons";



export default {
  name: "EmailLine",
  components: {
    TrashIcon,EditIcon,CheckIcon,
  },
    props: {
      resume: {
        type: Object,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
  },

  watch: {
    'resume.isSelected': function (val, oldVal) {
      let obj = { isSelected: val, index: this.index};
      this.$emit('updateResume',obj);
    },
  },
  data() {
    return{
      hover: false,
      editMode: true,
    }
  },
  methods: {
  /*  deleteItem(){
      this.$emit('deleteItem',this.index);
    },
    saveItem() {
      this.editMode = !this.editMode;
      this.$emit('saveItem',{id:this.index,name:this.emailTemplate.name});
    },

   */
    selectItem(){
      this.$emit('index',this.index);
    },
    goToResume(){
      let route = this.$router.resolve({path: '/resume/'+this.resume.resume_id});
      window.open(route.href, '_blank');
    },
  }

}
</script>

<style scoped>

</style>